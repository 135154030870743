<template>
	<div>
		<common-table
				:itemScope="['lsell', 'appointmentPlace', 'lfree']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="预约管理"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:lfree="{ item }">
				{{ item.lfree ? '免费' : '收费' }}
			</template>

			<template v-slot:appointmentPlace="{ item }">
				<v-btn depressed color="primary" @click="appointmentPlace(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					预约场所
				</v-btn>
			</template>
		</common-table>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"
			/>
		</template>

		<template v-if="combinationDialog">
			<combination :combination-dialog.sync="combinationDialog" @closeCombinationDialog="combinationDialog = false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/appointment.js';
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";
import editorText from "@/components/editorText/editorText.vue";
import qrCode from "@/components/qr-code/qr-code.vue"
import Combination from "./combination-qrcode-manage";

export default {
	mixins: [commonCurd],
	components: {
		qrCode,
		Combination
	},
	data() {
		return {
			api,
			combinationDialog: false,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: ''
			},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				{label: '组合项目', icon: 'erweima', event: 'openCombinationDialog'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入项目代码/名称', property: 'searchkey', width: '200px'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				}, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			// 表格头部字段
			headers: [
				{text: '项目代码', value: 'code'},
				{text: '项目名称', value: 'description'},
				{text: '预约场所', value: 'appointmentPlace'},
				{text: '是否免费', value: 'lfree'},
				{text: '状态', value: 'lsell'},
				{text: '排序号', value: 'seq'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "项目代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "项目名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'advdays',
						connector: {
							props: {
								required: true,
								label: "可预约未来天数",
								type: 'Number',
								rules: [
									v => v !== '' || '可预约未来天数不能为空',
									v => (/^([0-7]|([0-7]))$/.test(v) || '可预约未来天数为0~7')
								]
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'minstart',
						disabledDeleteDefault: true,
						connector: {
							props: {
								required: true,
								label: "最小可提前预约天数",
								type: 'Number',
								rules: [
									v => v !== '' || '最小可提前预约天数不能为空',
									v => (/^(?:[1-9]?\d|99)$/.test(v) || '最小可提前预约天数为0~99')
								]
							}
						},
						default: 0
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'mincancel',
						connector: {
							props: {
								required: true,
								label: "可提前取消小时数",
								type: 'Number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'advhour',
						connector: {
							props: {
								required: true,
								label: "当日预约提前小时数",
								type: 'Number'
							}
						}
					},
					{
						cols: 6,
						subassembly: rangeTimePicker,
						property: 'ndallowtime',
						connector: {
							props: {
								required: true,
								startText: '可预约明天项目时段',
								endText: ''
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'checkmode',
						connector: {
							props: {
								required: true,
								label: "核销模式",
								items: [{code: 0, desc: '过期自动核销'}, {code: 1, desc: '门票系统核销'}]
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lfree',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '免费',
								inset: true,
								dense: true
							}
						},
						default: false
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lshowalert',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '是否弹窗提示',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lrmno',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '填写预约房号',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '移动端列表图(686*320)',
					// 			fileType: 'actgroup'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'slidepics',
						connector: {
							props: {
								required: true,
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'actgroup'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '预约须知'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'noticetext',
						connector: {
							props: {
								multi: 3
							}
						}
					}
				]
			}
		}
	},
	methods: {
		appointmentPlace(item) {
			this.$router.push({name: 'appointmentPlace', query: {value: item}});
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 12
				}
			}
		},
		otherRightOperate(event) {
			if (event === 'openCombinationDialog')
				this.combinationDialog = true
		}
	}
}
</script>

<style scoped lang="scss">

</style>
